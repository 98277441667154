import axios, {AxiosResponse} from 'axios'
import {ResponseGraphQL} from '../../../../../_biha/helpers'
import {
  AddTagToSocialData,
  AddTagToSocialVariables,
  CheckoutSocialPurchaseData,
  CheckoutSocialPurchaseVariables,
  GetListCategoriesData,
  GetListCategoriesVariables,
  GetListPlansData,
  GetListPlansVariables,
  GetListProfilesData,
  GetListProfilesVariables,
  GetListRealEstateProjectsData,
  GetListRealEstateProjectsVariables,
  GetListSocialsData,
  GetListSocialsVariables,
  GetListTypesData,
  GetListTypesVariables,
  GetProfileDetailData,
  GetProfileDetailVariables,
  GetSocialDetailData,
  GetSocialDetailVariables,
  RequestedSocialData,
  RequestedSocialVariables,
  SaveSocialData,
  SaveSocialVariables,
  UpdateTableSettingsQueryData,
  UpdateTableSettingsQueryVariables,
} from './_models'

const MAIN_API_V2_ENDPOINT = process.env.REACT_APP_MAIN_API_V2_ENDPOINT
const ALITA_URL = `${MAIN_API_V2_ENDPOINT}/biha/graphql`

const getListCategories = async (variables: GetListCategoriesVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetListCategoriesData>> = await axios.post(
    ALITA_URL,
    {
      query: `
      query getListCategories(
        $searchString: String
        $limit: Int
        $page: Int
      ) {
        getListCategories(
          searchString: $searchString
          limit: $limit
          page: $page
        ) {
          hasNextPage
          hasPrevPage
          limit
          nextPage
          page
          pagingCounter
          prevPage
          total
          totalPages
          items {
            id
            name
          }
        }
      }`,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const getListPlans = async (variables: GetListPlansVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetListPlansData>> = await axios.post(ALITA_URL, {
    query: `
      query getListPlans(
        $searchString: String
        $limit: Int
        $page: Int
      ) {
        getListPlans(
          searchString: $searchString
          limit: $limit
          page: $page
        ) {
          hasNextPage
          hasPrevPage
          limit
          nextPage
          page
          pagingCounter
          prevPage
          total
          totalPages
          items {
            id
            name
          }
        }
      }`,
    variables: {
      ...variables,
    },
  })
  return response.data
}
const getListTypes = async (variables: GetListTypesVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetListTypesData>> = await axios.post(ALITA_URL, {
    query: `
      query getListTypes(
        $searchString: String
        $limit: Int
        $page: Int
      ) {
        getListTypes(
          searchString: $searchString
          limit: $limit
          page: $page
        ) {
          hasNextPage
          hasPrevPage
          limit
          nextPage
          page
          pagingCounter
          prevPage
          total
          totalPages
          items {
            id
            name
          }
        }
      }`,
    variables: {
      ...variables,
    },
  })
  return response.data
}
const getListSocials = async (variables: GetListSocialsVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetListSocialsData>> = await axios.post(ALITA_URL, {
    query: `
      query getListSocials(
        $searchString: String
        $socialName: String
        $plan: [ObjectID]
        $category: [ObjectID]
        $type: [ObjectID]
        $isCompany: Boolean
        $isPersonal: Boolean
        $limit: Int
        $page: Int
        $paymentStatus: [SocialPurchaseStatusEnum]
      ) {
        getListSocials(
          searchString: $searchString
          socialName: $socialName
          plan: $plan
          category: $category
          type: $type
          isCompany: $isCompany
          isPersonal: $isPersonal
          limit: $limit
          page: $page
          paymentStatus: $paymentStatus
        ) {
          hasNextPage
          hasPrevPage
          limit
          nextPage
          page
          pagingCounter
          prevPage
          total
          totalPages
          items {
            id
            socialAvatar {
              id
              fileId
              originalUrl
            }
            socialName
            socialUrl
            idSocial
            type {
              id
              name
            }
            description
            plan {
              id
              name
              price
            }
            currentSize
            purchasedSize
            category {
              id
              name
            }
            rating
            tags {
              id
              projectName
            }
              paymentStatus
          }
        }
      }`,
    variables: {
      ...variables,
    },
  })
  return response.data
}
const getSocialDetail = async (variables: GetSocialDetailVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetSocialDetailData>> = await axios.post(
    ALITA_URL,
    {
      query: `
      query getSocialDetail($id: ObjectID!) {
        getSocialDetail(id: $id) {
          id
          socialAvatar {
            id
            fileId
            originalUrl
          }
          socialName
          socialUrl
          idSocial
          type {
            id
            name
          }
          description
          plan {
            id
            name
            price
          }
          currentSize
          purchasedSize
          category {
            id
            name
          }
          rating
          tags {
            id
            projectName
          }
          genderPercentages
          followerPercentages
          friendPercentages
          agePercentages
          relationshipPercentages
          totalProfiles
          profilesWithEmail
          profilesWithDob
          profilesWithRelationship
          provinceCounts
          paymentStatus
        }
      }`,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const getProfileDetail = async (variables: GetProfileDetailVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetProfileDetailData>> = await axios.post(
    ALITA_URL,
    {
      query: `
      query getProfileDetail($id: ObjectID!) {
        getProfileDetail(id: $id) {
          id
          name
          gender
          phone
          dob
          province
          district
          email
        }
      }`,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const requestedSocial = async (variables: RequestedSocialVariables) => {
  const response: AxiosResponse<ResponseGraphQL<RequestedSocialData>> = await axios.post(
    ALITA_URL,
    {
      query: `
      mutation requestedSocial($input: RequestedSocialInputType!) {
        requestedSocial(input: $input) {
          msg
          success
        }
      }
        `,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const saveSocial = async (variables: SaveSocialVariables) => {
  const response: AxiosResponse<ResponseGraphQL<SaveSocialData>> = await axios.post(ALITA_URL, {
    query: `
      mutation saveSocial($id: ObjectID!) {
        saveSocial(id: $id) {
          msg
          success
        }
      }
        `,
    variables: {
      ...variables,
    },
  })
  return response.data
}
const addTagToSocial = async (variables: AddTagToSocialVariables) => {
  const response: AxiosResponse<ResponseGraphQL<AddTagToSocialData>> = await axios.post(ALITA_URL, {
    query: `
      mutation addTagToSocial($id: ObjectID!, $tagId: [ObjectID!]!) {
        addTagToSocial(id: $id, tagId: $tagId,) {
          msg
          success
        }
      }
        `,
    variables: {
      ...variables,
    },
  })
  return response.data
}
function updateTableSettings(variables: UpdateTableSettingsQueryVariables) {
  return axios.post<ResponseGraphQL<UpdateTableSettingsQueryData>>(
    `${MAIN_API_V2_ENDPOINT}/users/graphql`,
    {
      query: `
    mutation ($input: tableInput!) {
      updateTableSettings(input: $input) {
        msg
        payload
        success
      }
    }
      `,
      variables: {
        ...variables,
      },
    }
  )
}
const getListProfiles = async (variables: GetListProfilesVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetListProfilesData>> = await axios.post(
    ALITA_URL,
    {
      query: `
      query getListProfiles(
        $social: ObjectID!
        $search: String
        $gender: [SocialGenderEnum]
        $name: String
        $relationship: [SocialRelationshipEnum]
        $friendsCount: RangeValueInput
        $followersCount: RangeValueInput
        $age: RangeValueInput
        $dob: InternalFromToDateStringInputType
        $limit: Int
        $page: Int
      ) {
        getListProfiles(
          social: $social
          search: $search
          gender: $gender
          name: $name
          relationship: $relationship
          friendsCount: $friendsCount
          followersCount: $followersCount
          age: $age
          dob: $dob
          limit: $limit
          page: $page
        ) {
          hasNextPage
          hasPrevPage
          limit
          nextPage
          page
          pagingCounter
          prevPage
          total
          totalPages
          items {
            id   
            profileUrl
            name
            gender
            phone
            dob
            province
            district
            friendsCount
            followersCount
            relationship
            email
            social {
              id
            }
            company
          }
        }
      }`,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const getListRealEstateProjects = async (variables: GetListRealEstateProjectsVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetListRealEstateProjectsData>> = await axios.post(
    ALITA_URL,
    {
      query: `
      query getListRealEstateProjects(
        $inputData: filterRealEstateProjectInput
        $searchString: String
        $page: Int
        $limit: Int
        $sort: String
        $isDeleted: Boolean
        $includedProjectIds: [String]
        $excludedProjectIds: [ObjectID]
        $isBasic: Boolean
      ) {
        getListRealEstateProjects(
          inputData: $inputData
          searchString: $searchString
          page: $page
          limit: $limit
          sort: $sort
          isDeleted: $isDeleted
          includedProjectIds: $includedProjectIds
          excludedProjectIds: $excludedProjectIds
          isBasic: $isBasic
        ) {
          hasNextPage
          hasPrevPage
          limit
          nextPage
          page
          pagingCounter
          prevPage
          total
          totalPages
          items {
            id   
            projectName
          }
        }
      }`,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const checkoutSocialPurchase = async (variables: CheckoutSocialPurchaseVariables) => {
  const response: AxiosResponse<ResponseGraphQL<CheckoutSocialPurchaseData>> = await axios.post(
    ALITA_URL,
    {
      query: `
      mutation checkoutSocialPurchase($idSocial: ObjectID!, $paymentType: SocialPurchasePaymentTypeEnum, $redirectUrl: String) {
        checkoutSocialPurchase(idSocial: $idSocial, paymentType: $paymentType, redirectUrl: $redirectUrl) {
          msg
          success
          payload
        }
      }
        `,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}

export {
  addTagToSocial,
  checkoutSocialPurchase,
  getListCategories,
  getListPlans,
  getListProfiles,
  getListRealEstateProjects,
  getListSocials,
  getListTypes,
  getProfileDetail,
  getSocialDetail,
  requestedSocial,
  saveSocial,
  updateTableSettings,
}
