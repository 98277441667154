import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MasterLayout} from '../../_biha/layout/MasterLayout'
import {getCSSVariableValue} from '../../_biha/assets/ts/_utils'
import {SUB_PATHS} from '../../_biha/helpers'
import {
  REQUESTED_SOCIAL_MANAGEMENT_PATH,
  SHARED_USERS_DATA_PATH,
  USERS_DATA_PATH,
  WithChildren,
} from '../../_biha/helpers'
import {Module} from '../modules/auth'
import AdminRoutesWrapper from './AdminRoutes'

const PrivateRoutes = () => {
  const UsersDataPage = lazy(() =>
    import('../modules/apps/users-data-management/UsersDataPage').then((module) => ({
      default: module.UsersDataPage,
    }))
  )

  const SharedUsersDataPage = lazy(() =>
    import('../modules/apps/shared-users-data-management/SharedUsersDataPage').then((module) => ({
      default: module.SharedUsersDataPage,
    }))
  )
  const RequestedSocialPage = lazy(() =>
    import('../modules/apps/requested-social-management/RequestedSocialPage').then((module) => ({
      default: module.RequestedSocialPage,
    }))
  )

  const GroupSettingsPage = lazy(() =>
    import('../modules/apps/group-settings/GroupSettingsPage').then((module) => ({
      default: module.GroupSettingsPage,
    }))
  )
  const GroupManagementPage = lazy(() =>
    import('../modules/apps/group-management/GroupManagementPage').then((module) => ({
      default: module.GroupManagementPage,
    }))
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to={`/${USERS_DATA_PATH.MAIN}`} />} />
        {/* Lazy Modules */}
        <Route
          path={`${USERS_DATA_PATH.MAIN}/*`}
          element={
            <SuspensedView>
              <UsersDataPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SHARED_USERS_DATA_PATH.MAIN}/*`}
          element={
            <SuspensedView>
              <SharedUsersDataPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SUB_PATHS.GROUP_SETTINGS.INDEX}/*`}
          element={
            <SuspensedView>
              <GroupSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SUB_PATHS.GROUP_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <GroupManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${REQUESTED_SOCIAL_MANAGEMENT_PATH.MAIN}/*`}
          element={
            <AdminRoutesWrapper requiredModule={Module.ADMIN_MANAGE_CUSTOMER_DATASOURCE}>
              <SuspensedView>
                <RequestedSocialPage />
              </SuspensedView>
            </AdminRoutesWrapper>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
