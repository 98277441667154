import clsx from 'clsx'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG, WithChildren} from '../../../../../_biha/helpers'
import {useLayout} from '../../../../../_biha/layout/core'
import {RequestSocialModal} from '../../users-data-management/users-data-list/request-social-modal/RequestSocialModal'

interface Props {
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItemButton: FC<Props & WithChildren> = ({
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {config} = useLayout()
  const {app} = config
  const [isShowingRequestedSocialModal, toggleShowingRequestedSocialModal] =
    useState<boolean>(false)

  const handleRequestedSocialModal = () => {
    toggleShowingRequestedSocialModal(!isShowingRequestedSocialModal)
  }

  return (
    <div className='menu-item'>
      <button
        className='w-100 d-flex align-items-center fw-semibold text-gray-700 text-hover-gray-900 bg-white me-0 border-0'
        style={{padding: '0.65rem 1rem'}}
        onClick={handleRequestedSocialModal}
      >
        {hasBullet && (
          <span
            className='d-flex align-items-center justify-content-center flex-shrink-0'
            style={{marginRight: '0.5rem', width: '1.25rem'}}
          >
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('ki-duotone fs-3 me-2', fontIcon)}>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        <span className='menu-title'>{title}</span>
      </button>
      <Modal
        show={isShowingRequestedSocialModal}
        onHide={handleRequestedSocialModal}
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered min-w-660px mw-660px'
      >
        <RequestSocialModal onClose={handleRequestedSocialModal} />
      </Modal>
    </div>
  )
}

export {SidebarMenuItemButton}
