/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {SidebarMenuItemButton} from '../../../../../app/modules/apps/components/sidebar-menu-item-button/SidebarMenuItemButton'
import {SHARED_USERS_DATA_PATH, SUB_PATHS, USERS_DATA_PATH} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => (
  <Fragment>
    <SidebarMenuItemWithSub
      to={`/${USERS_DATA_PATH.MAIN}`}
      title='Dữ liệu cộng đồng'
      icon='/media/biha/icons/svg/fluent_text-bullet-list-square-toolbox-20-filled.svg'
    >
      <SidebarMenuItem
        hasBullet
        to={`/${USERS_DATA_PATH.MAIN}/${USERS_DATA_PATH.LIST}`}
        title='Trang/ Nhóm MXH cộng đồng'
      />
      <SidebarMenuItemButton hasBullet title='Thêm nhóm bằng URL' />
    </SidebarMenuItemWithSub>
    <SidebarMenuItemWithSub
      to={`/${SHARED_USERS_DATA_PATH.MAIN}`}
      title='Dữ liệu khách hàng của bạn'
      icon='/media/biha/icons/svg/fluent_text-bullet-list-square-person-32-filled.svg'
    >
      <SidebarMenuItem
        hasBullet
        to={`/${SHARED_USERS_DATA_PATH.MAIN}/${SHARED_USERS_DATA_PATH.COMPANY_USERS}`}
        title='Trang/ Nhóm MXH của công ty'
      />
      <SidebarMenuItem
        hasBullet
        to={`/${SHARED_USERS_DATA_PATH.MAIN}/${SHARED_USERS_DATA_PATH.MY_USERS}`}
        title='Trang/ Nhóm MXH của tôi'
      />
    </SidebarMenuItemWithSub>
    <SidebarMenuItem
      //hasBullet
      to={`/${SUB_PATHS.GROUP_SETTINGS.INDEX}/list`}
      title='Cài đặt trang/ nhóm'
      icon='/media/biha/icons/svg/fluent_text-bullet-list-square-person-32-filled.svg'
    />
  </Fragment>
)

export {SidebarMenuMain}
