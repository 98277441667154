import * as yup from 'yup'
import { ResponseGraphQL } from '../../../../../_biha/helpers'
import { Boolean, FileWithFullUrls, Float, JSONObject, Number, String } from '../../../auth'
import { DateTime, Int, ObjectID } from '../../core/_models'
import { MutationOfString } from '../../group-management/features/core/_models'
import { SocialPurchaseStatusEnum } from '../../group-management/group-management-detail/core/_models'

export enum SocialGenderEnum {
  male = 'male',
  female = 'female',
  other = 'other',
}
export enum SocialRelationshipEnum {
  Single = 'Single',
  Married = 'Married',
  Divorce = 'Divorce',
  Dating = 'Dating',
  Other = 'Other',
}
export interface InternalFromToDateStringInputType {
  from?: String
  to?: String
}
export interface RangeValueInput {
  from?: Float
  key?: String
  to?: Float
  unit?: String
  value?: String
}
export interface Type {
  id?: String
  name?: String
}
export interface Plan {
  id?: String
  name?: String
  price?: Number
}
export interface Category {
  id?: String
  name?: String
}
export interface RealEstateProject {
  id?: String
  projectName?: String
  // ...
}
export interface GetListPlansVariables {
  searchString?: String
  limit?: Number
  page?: Number
}
export interface GetListCategoriesVariables {
  searchString?: String
  limit?: Number
  page?: Number
}
export interface GetListTypesVariables {
  searchString?: String
  limit?: Number
  page?: Number
}
export interface GetListPlansData {
  getListPlans?: GetListPlansPayload
}
export interface GetListPlansPayload extends Omit<GetListSocialsResponsePaging, 'links'> {
  items?: Plan[]
}
export interface GetListTypesData {
  getListTypes?: GetListTypesPayload
}
export interface GetListTypesPayload extends Omit<GetListSocialsResponsePaging, 'links'> {
  items?: Type[]
}
export interface GetListCategoriesData {
  getListCategories?: GetListCategoriesPayload
}
export interface GetListCategoriesPayload extends Omit<GetListSocialsResponsePaging, 'links'> {
  items?: Category[]
}
export interface GetListProfilesVariables {
  social: ObjectID
  search?: String
  gender?: SocialGenderEnum[]
  name?: String
  relationship?: SocialRelationshipEnum[]
  friendsCount?: RangeValueInput
  followersCount?: RangeValueInput
  age?: RangeValueInput
  dob?: InternalFromToDateStringInputType
  limit?: Int
  page?: Int
}
export interface GetListProfilesData {
  getListProfiles?: GetListProfilesPayload
}
export interface GetListProfilesPayload extends Omit<GetListSocialsResponsePaging, 'links'> {
  items?: Profile[]
}
export interface GetListSocialsVariables {
  socialName?: String
  plan?: String[]
  category?: String[]
  type?: String[]
  isCompany?: Boolean
  isPersonal?: Boolean
  limit?: Number
  page?: Number
  paymentStatus?: SocialPurchaseStatusEnum[]
}
export interface GetListSocialsData {
  getListSocials?: GetListSocialsPayload
}
export interface GetListSocialsPayload extends Omit<GetListSocialsResponsePaging, 'links'> {
  items?: Social[]
}
export interface GetListSocialsResponsePaging {
  hasNextPage?: Boolean
  hasPrevPage?: Boolean
  limit?: Number
  nextPage?: Number
  page?: Number
  pagingCounter?: Number
  prevPage?: Number
  total?: Number
  totalPages?: Number
  links?: {
    active: Boolean
    disabled: Boolean
    label: String | undefined
    page: Number | undefined
  }[]
}
export interface GetListProfilesResponsePaging {
  hasNextPage?: Boolean
  hasPrevPage?: Boolean
  limit?: Number
  nextPage?: Number
  page?: Number
  pagingCounter?: Number
  prevPage?: Number
  total?: Number
  totalPages?: Number
  links?: {
    active: Boolean
    disabled: Boolean
    label: String | undefined
    page: Number | undefined
  }[]
}

export interface Profile {
  id?: String
  profileUrl?: String
  name?: String
  gender?: String
  phone?: String
  dob?: DateTime
  province?: String
  district?: String
  friendsCount?: Int
  followersCount?: Int
  relationship?: String
  email?: String
  social?: Social
  company?: String[]
}
export interface Social {
  id?: String
  socialAvatar?: FileWithFullUrls
  socialName?: String
  socialUrl?: String
  idSocial?: String
  type?: Type
  description?: String
  plan?: Plan
  currentSize?: Int
  purchasedSize?: Int
  category?: Category[]
  rating?: Int
  tags?: RealEstateProject[]
  genderPercentages?: JSONObject[]
  followerPercentages?: JSONObject[]
  friendPercentages?: JSONObject[]
  agePercentages?: JSONObject[]
  relationshipPercentages?: JSONObject[]
  provinceCounts?: JSONObject[]
  totalProfiles?: Int
  profilesWithEmail?: Int
  profilesWithDob?: Int
  profilesWithRelationship?: Int
  paymentStatus?: SocialPurchaseStatusEnum
}
export type GetListProfilesQueryResponse = ResponseGraphQL<GetListProfilesData>
export type GetListSocialsQueryResponse = ResponseGraphQL<GetListSocialsData>
export interface GetSocialDetailVariables {
  id: String
}
export interface GetSocialDetailData {
  getSocialDetail?: Social
}
export type GetSocialDetailQueryResponse = ResponseGraphQL<GetSocialDetailData>
export interface RequestedSocialInputType {
  socialName?: String
  url: String
  idSocial?: String
  type?: ObjectID
}
//
export interface AddTagToSocialVariables {
  id: String
  tagId: String[]
}
export interface AddTagToSocialResponse {
  msg?: String
  success?: Boolean
}
export interface AddTagToSocialData {
  addTagToSocial?: AddTagToSocialResponse
}
export interface SaveSocialVariables {
  id: String
}
export interface SaveSocialResponse {
  msg?: String
  success?: Boolean
}
export interface SaveSocialData {
  saveSocial?: SaveSocialResponse
}
export interface RequestedSocialVariables {
  input: RequestedSocialInputType
}
export interface RequestedSocialResponse {
  msg?: String
  success?: Boolean
}
export interface RequestedSocialData {
  requestedSocial?: RequestedSocialResponse
}

export interface GetListSocialsFilterFormValues {
  page?: number
  limit?: number
  sort?: String
  socialName?: String
  plan?: String[]
  category?: String[]
  type?: String[]
}
export const initialValuesGetListSocialsFilterForm: GetListSocialsFilterFormValues = {
  page: 1,
  limit: undefined,
  sort: '',
  socialName: '',
  plan: [],
  category: [],
  type: [],
}
export interface DateFilterFormValues {
  createdAt?: string[]
  updatedAt?: string[]
}
export const initialValuesDateFilterForm: DateFilterFormValues = {
  createdAt: [],
  updatedAt: [],
}
export interface UpdateTableSettingsQueryVariables {
  input: TableInput
}
export interface TableInput {
  tableId: String
  columns: ColumnInput[]
}
export interface ColumnInput {
  checked?: Boolean
  id?: String
  label?: String
}
export interface UpdateTableSettingsQueryData {
  updateTableSettings?: {
    success?: Boolean
    msg?: String
    payload?: String
  }
}
export interface GetListRealEstateProjectsVariables {
  // inputData?: filterRealEstateProjectInput
  searchString?: String
  page?: Int
  limit?: Int
  sort?: String
  isDeleted?: Boolean
  includedProjectIds?: String[]
  excludedProjectIds?: ObjectID[]
  isBasic?: Boolean
}
export interface GetListRealEstateProjectsResponsePaging {
  hasNextPage?: Boolean
  hasPrevPage?: Boolean
  limit?: Number
  nextPage?: Number
  page?: Number
  pagingCounter?: Number
  prevPage?: Number
  total?: Number
  totalPages?: Number
}
export interface GetListRealEstateProjectsPayload extends GetListRealEstateProjectsResponsePaging {
  items?: RealEstateProject[]
}
export interface GetListRealEstateProjectsData {
  getListRealEstateProjects?: GetListRealEstateProjectsPayload
}
export interface GetProfileDetailVariables {
  id: String
}
export interface GetProfileDetailData {
  getProfileDetail?: Profile
}
export type GetProfileDetailQueryResponse = ResponseGraphQL<GetProfileDetailData>
export interface RequestedSocialFormValues {
  url?: String
}
export interface AddTagToSocialFormValues {
  tagId?: String[]
}
export const requestedSocialSchema = yup.object().shape({
  url: yup.string().url('URL không hợp lệ'),
})
export interface CheckoutSocialPurchaseVariables {
  idSocial: ObjectID
  paymentType?: SocialPurchasePaymentTypeEnum
  redirectUrl?: String
}
export enum SocialPurchasePaymentTypeEnum {
  NOW = 'NOW',
  LATER = 'LATER',
}
export interface CheckoutSocialPurchaseData {
  checkoutSocialPurchase?: MutationOfString
}
export interface PaymentTypeForm {
  paymentType?: SocialPurchasePaymentTypeEnum;
}

export const paymentTypeFormDefaultValues: PaymentTypeForm = {
  paymentType: SocialPurchasePaymentTypeEnum.NOW,
};