import {includes} from 'lodash'
import React from 'react'
import {Navigate} from 'react-router-dom'
import {Module, useAuth} from '../modules/auth'

interface AdminRoutesWrapperProps {
  children: React.ReactNode
  requiredModule: Module
}

const AdminRoutesWrapper: React.FC<AdminRoutesWrapperProps> = ({children, requiredModule}) => {
  const {currentUser} = useAuth()

  if (!currentUser || !includes(currentUser?.modules, requiredModule)) {
    return <Navigate to='/error/403' replace />
  }

  return <>{children}</>
}

export default AdminRoutesWrapper
