import {FormikProvider, useFormik} from 'formik'
import {useContext} from 'react'
import {FormInput} from '../../../components/form-input/FormInput'
import {ToastContext} from '../../../components/toast/Toast'
import {RequestedSocialFormValues, requestedSocialSchema} from '../../core/_models'
import {requestedSocial} from '../../core/_requests'

interface Props {
  onClose?: () => void
}
const RequestSocialModal = ({onClose}: Props) => {
  const {showToast} = useContext(ToastContext)
  const form = useFormik<RequestedSocialFormValues>({
    validateOnChange: true,
    initialValues: {
      url: '',
    },
    validationSchema: requestedSocialSchema,
    onSubmit: async (values) => {
      try {
        await requestedSocial({input: {url: values?.url ?? ''}})
        showToast?.('success', 'Thành công', 'Yêu cầu thêm người dùng thành công!')
        onClose?.()
      } catch (error) {
        console.error(error)
        showToast?.('error', 'Thất bại', 'Yêu cầu thêm người dùng thất bại!')
      }
    },
  })
  const {handleSubmit, getFieldProps, submitForm, values, errors} = form
  const urlError = form.touched.url && Boolean(errors?.url)
  const urlWatch = values.url

  return (
    <FormikProvider value={form}>
      <form onSubmit={handleSubmit} className='overflow-visible w-100'>
        <div className='modal-content w-660px py-6'>
          <div className='w-100 d-flex justify-content-between px-6'>
            <span className='fs-2 fw-bold text-center lh-lg'>Thêm người dùng</span>
            <div className='btn btn-icon btn-sm btn-active-light-primary' onClick={onClose}>
              <i className='ki-duotone ki-cross fs-2x'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </div>
          </div>
          <div className='w-100 my-2 border-gray-300 border-bottom'></div>
          <div className='d-flex flex-column gap-6 px-15 mt-10 mb-12'>
            <div className='d-flex align-items-center position-relative'>
              <i
                className='ki-duotone ki-magnifier fs-2 position-absolute ms-3'
                style={{zIndex: 1, top: '10px'}}
              >
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <div className='w-100'>
                <FormInput
                  {...getFieldProps('url')}
                  name='url'
                  type='text'
                  placeholder='Thêm link facebook của bạn'
                  autoComplete='off'
                  wrapperClassName='ps-10'
                />
              </div>
            </div>
            <div className='text-gray-800 fs-6' style={{lineHeight: '17px'}}>
              <p className='mb-5'>
                Vui lòng cung cấp cho chúng tôi URL của trang Facebook mà bạn muốn lấy dữ liệu, bao
                gồm Fanpage Facebook, Nhóm Công khai, Nhóm Riêng tư, Trang Cá nhân, và Bài đăng.
              </p>
              <ul>
                <li>Các Fanpage sẽ cung cấp thông tin về người theo dõi.</li>
                <li>Các Nhóm Công khai/Nhóm Riêng tư sẽ cung cấp thông tin về thành viên.</li>
                <li>Các trang cá nhân sẽ cung cấp thông tin về bạn bè và người theo dõi.</li>
                <li>
                  Các bài đăng sẽ cung cấp thông tin về người dùng tương tác: Lượt thích, Chia sẻ,
                  và Bình luận.
                </li>
              </ul>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <button type='button' className='btn btn-light text-gray-800' onClick={onClose}>
              Huỷ
            </button>
            <button
              type='button'
              disabled={!urlWatch || urlError}
              className='ms-4 btn btn-primary'
              data-bs-dismiss='modal'
              onClick={submitForm}
            >
              Yêu cầu
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

export {RequestSocialModal}
