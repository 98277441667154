import clsx from 'clsx'
import {useField} from 'formik'
import {gt, isEmpty, isEqual, lt} from 'lodash'
import {ChangeEvent, FC, Fragment, InputHTMLAttributes, KeyboardEvent} from 'react'
import {
  convertToVietnameseCurrencyShort,
  convertUSDToVietnameseCurrencyShort,
  numberWithCommas,
} from '../../../../../_biha/utils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  wrapperClassName?: string
  maxLength?: number
  isNumber?: boolean
  isCurrency?: boolean
  currency?: 'USD' | 'VND'
}

const FormInput: FC<Props> = ({
  name,
  wrapperClassName,
  maxLength,
  isNumber,
  isCurrency,
  currency,
  ...props
}) => {
  const {type, disabled} = props
  const [, meta, helpers] = useField(name)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value
    if (isNumber) {
      inputValue = inputValue.replace(/[^0-9]/g, '')
      inputValue = inputValue.replace(/^0+/, '')
      if (!isNaN(Number(inputValue.replaceAll(',', '')))) {
        inputValue = numberWithCommas(inputValue)
      }
      helpers.setValue(inputValue)
    } else {
      helpers.setValue(inputValue)
    }
  }
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isEqual(type, 'number') || isNumber) {
      if (
        isEqual(event.key, 'e') ||
        isEqual(event.key, '+') ||
        isEqual(event.key, '-') ||
        gt(Number(event.key), 9) ||
        lt(Number(event.key), 0)
      ) {
        event.preventDefault()
      }
    }
  }

  return (
    <Fragment>
      <input
        {...props}
        name={name}
        type={type ?? 'text'}
        inputMode={isEqual(type, 'number') ? 'numeric' : 'text'}
        autoComplete='off'
        className={clsx(
          wrapperClassName,
          'form-control form-control-solid h-40px min-h-40px mh-40px mb-3 mb-lg-0 pe-8 border-gray-300 fs-7 fw-bold text-gray-600',
          {
            'is-invalid': meta.touched && meta.error,
          },
          {
            'is-valid': meta.touched && !meta.error,
          },
          {
            'bg-gray-700': disabled,
          },
          {
            'bg-white': !disabled,
          }
        )}
        maxLength={maxLength}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {isCurrency && !isEmpty(meta.value) && (
        <div className='mt-2 ms-2'>
          <span role='alert' className='text-danger fw-bold'>
            {isEqual(currency, 'USD')
              ? convertUSDToVietnameseCurrencyShort(parseFloat(meta.value?.replace(/,/g, '')))
              : convertToVietnameseCurrencyShort(parseFloat(meta.value?.replace(/,/g, '')))}
          </span>
        </div>
      )}
      {meta.touched && meta.error && (
        <div className='mt-2'>
          <span role='alert' className='text-danger'>
            {meta.error}
          </span>
        </div>
      )}
    </Fragment>
  )
}

export {FormInput}
