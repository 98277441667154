const QUERIES = {
  CONFIG: 'config',
  USERS_DATA_LIST: 'users-data-list',
  SHARED_USERS_DATA_LIST: 'shared-users-data-list',
  REQUESTED_SOCIAL_MANAGEMENT_LIST: 'shared-users-data-list',
  CUSTOMERS_LIST: 'customers-list',
  PROVINCE_LIST: 'province-list',
  DISTRICT_LIST: 'district-list',
  WARD_LIST: 'ward-list',
  STREET_LIST: 'street-list',
  COMMENT: 'comment',
  PROJECT_LIST: 'project-list',
  USER_LIST: 'user-list',
  CREATED_AND_UPDATED_BY: 'created-and-updated-by',
  INTERACTION_HISTORY: 'interaction-history',
  PLANS_LIST: 'plans-list',
  TYPES_LIST: 'types-list',
  CATEGORIES_LIST: 'categories-list',
  SOCIAL_USER_LIST: 'social-user-list',
}
const USERS_DATA_PATH = {
  MAIN: 'users-data',
  LIST: 'list',
  OVERVIEW: 'overview',
  NEW: 'new',
}
const SHARED_USERS_DATA_PATH = {
  MAIN: 'shared-users-data',
  COMPANY_USERS: 'company-users',
  MY_USERS: 'my-users',
  OVERVIEW: 'overview',
  ADD_TO_CRM:'add-to-crm',
}
const REQUESTED_SOCIAL_MANAGEMENT_PATH = {
  MAIN: 'requested-social-management',
  LIST: 'list',
}
const PHONE_REGEX = /^(0)(3[2-9]|5[2689]|7[0|6-9]|8[1-9]|9[0-9])[0-9]{7}$/
const NAME_REGEX = /^[A-Za-zÀ-ỹ\s]+$/
const SUB_QUERIES = {
  CONTRACT_CONFIGURATION: {
    ALL_CONTRACT_TYPE: 'all-contract-type',
    CONTRACT_TYPE_DETAIL: 'contract-type-detail',
    POST_CONTRACT: 'post-contract',
    UPDATE_CONTRACT: 'update-contract',
    DELETE_CONTRACT: 'delete-contract',
    ALL_EMPLOYEE: 'all-employee',
    ALL_CONTRACT: 'all-contract',
    CONTRACT_DETAIL: 'contract-detail',
  },
  GROUP_MANAGEMENT: {
    ALL_GROUP: 'all-group',
    GROUP_DETAIL: 'group-detail',
  },
  CATEGORY: {
    ALL_CATEGORY: 'all-category',
  },
  CLASSIFICATION: {
    ALL_CLASSIFICATION: 'all-classification',
  },
  PACKAGE: {
    ALL_PACKAGE: 'all-package',
  },
  HISTORY: {
    INTERACTION_HISTORY: 'interaction-history',
  },
}
const SUB_PATHS = {
  USERS_DATA: {
    LIST: 'users-data-list',
    INDEX: 'users-data',
    COMPANY_USERS: 'company-users-data',
    MY_USERS: 'my-users-data',
    OVER_VIEW: 'overview',
  },
  GROUP_SETTINGS: {
    INDEX: 'group-settings',
  },
  GROUP_MANAGEMENT: {
    INDEX: 'group-management',
    LIST: 'list',
    OVER_VIEW: 'overview',
    NEW: 'new',
    EDIT: 'edit',
    IMPORT_USER: 'import-user',
  },
}
const SPECIAL_KEYS = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowTop',
  'ArrowBottom',
  'Delete',
  'Backspace',
  'Enter',
]

export {
  NAME_REGEX,
  PHONE_REGEX,
  QUERIES,
  REQUESTED_SOCIAL_MANAGEMENT_PATH,
  SHARED_USERS_DATA_PATH,
  SPECIAL_KEYS,
  SUB_PATHS,
  SUB_QUERIES,
  USERS_DATA_PATH
}

